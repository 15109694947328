<template>
  <div style="background-color: white;" class="pt-10 px-3">
    <v-container fluid class="v-container-fluid">
      <h2 class="mon-bold" style="font-size: min(max(35px, 5vw), 70px); color: #304458; margin-bottom: 45px;">
        {{ texts.about.welcome }}
      </h2>
      <h4 class="mon" style="font-size: 26px; text-wrap: wrap; max-width: 1088px; color: #304458; margin-bottom: 45px; font-weight: 500;">{{ texts.about.description }}</h4>
      <div
        class="display-flex flex-wrap"
        style="gap: 20px"
        :class="{'justify-center': $vuetify.breakpoint.smAndDown || $vuetify.breakpoint.mdAndDown}"
      >
        <div
          class="custom-card"
          @mouseover="isHovered = true"
          @mouseout="isHovered = false"
        >
          <h6 class="mon" :class="{ 'hovered-text': isHovered, 'not-hovered-text': !isHovered }">
            {{ texts.about.platformSection }}
          </h6>
          <div style="display: flex; justify-content: space-between; align-items: center; padding: 0px 30px 15px;" :class="{ 'hovered-text': isHovered }">
            <h6 class="mon" :class="{ 'hovered-text__sub': isHovered, 'not-hovered-text__sub': !isHovered }">{{ texts.about.platformTitle }}</h6>
            <v-img
              src="@/assets/images/Grupo26851.png"
              alt="Descripción de la imagen"
              max-width="48"
              max-height="48"
            ></v-img>
          </div>
        </div>
        <div
          class="custom-card__insurance"
          @mouseover="isHoveredInsurance = true"
          @mouseout="isHoveredInsurance = false"
        >
          <h6 class="mon" :class="{ 'hovered-text__insurance': isHoveredInsurance, 'not-hovered-text': !isHoveredInsurance }">
            {{ texts.about.insuranceSection }}
          </h6>
          <div style="display: flex; justify-content: space-between; align-items: center; padding: 0px 30px 15px;" :class="{ 'hovered-text': isHoveredInsurance }">
            <h6 class="mon" :class="{ 'hovered-text__sub': isHoveredInsurance, 'not-hovered-text__sub': !isHoveredInsurance }">{{ texts.about.insuranceTitle }}</h6>
            <v-img
              src="@/assets/images/Grupo 26852.png"
              alt="Descripción de la imagen"
              max-width="48"
              max-height="48"
            ></v-img>
          </div>
        </div>
        <div
          class="custom-card__logistic"
          @mouseover="isHoveredLogistic = true"
          @mouseout="isHoveredLogistic = false"
        >
          <h6 class="mon" :class="{ 'hovered-text__logistic': isHoveredLogistic, 'not-hovered-text': !isHoveredLogistic }">
            {{ texts.about.logisticSection }}
          </h6>
          <div style="display: flex; justify-content: space-between; align-items: center; padding: 0px 30px 15px;" :class="{ 'hovered-text': isHoveredLogistic }">
            <h6 class="mon" :class="{ 'hovered-text__sub': isHoveredLogistic, 'not-hovered-text__sub': !isHoveredLogistic }">{{ texts.about.logisticTitle }}</h6>
            <v-img
              src="@/assets/images/Grupo 26853.png"
              alt="Descripción de la imagen"
              max-width="48"
              max-height="48"
            ></v-img>
          </div>
        </div>
        <div
          class="custom-card__finance"
          @mouseover="isHoveredFinance = true"
          @mouseout="isHoveredFinance = false"
        >
          <h6 class="mon" :class="{ 'hovered-text__finance': isHoveredFinance, 'not-hovered-text': !isHoveredFinance }">
            {{ texts.about.financeSesction }}
          </h6>
          <div style="display: flex; justify-content: space-between; align-items: center; padding: 0px 30px 15px;" :class="{ 'hovered-text': isHoveredFinance }">
            <h6 class="mon" :class="{ 'hovered-text__sub': isHoveredFinance, 'not-hovered-text__sub': !isHoveredFinance }">{{ texts.about.financeTitle }}</h6>
            <v-img
              src="@/assets/images/Grupo 26854.png"
              alt="Descripción de la imagen"
              max-width="48"
              max-height="48"
            ></v-img>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeFeaturedSupplierSkeletonLayout",
  data() {
    return {
      imgPlatform: require('@/assets/images/Grupo26851.png'),
      isHovered: false,
      isHoveredInsurance: false,
      isHoveredLogistic: false,
      isHoveredFinance: false,
    };
  },
};
</script>

<style scoped>
.custom-card {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}

.custom-card__insurance {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}
.custom-card__logistic {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}
.custom-card__finance {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}
.hovered-text {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__logistic {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__finance {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__insurance {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__sub {
  color: white !important;
  font-size: 32px;
  font-weight: 500;
}
.not-hovered-text {
  font-size: 22px; 
  font-weight: 500; 
  padding: 30px;
}
.not-hovered-text__sub {
  font-size: 22px; 
  font-weight: 500;
}

.custom-card:hover {
  background-image: url('../../../assets/images/Grupo 26860.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}

.custom-card__insurance:hover {
  background-image: url('../../../assets/images/Grupo 26859.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}

.custom-card__logistic:hover {
  background-image: url('../../../assets/images/Grupo 26858.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}

.custom-card__finance:hover {
  background-image: url('../../../assets/images/Grupo 26857.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}
</style>